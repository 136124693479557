import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import HomePage from './components/HomePage.vue'
import AboutPage from './components/AboutPage.vue'
import ProductsPage from './components/ProductsPage.vue'
import ServicePage from './components/ServicePage.vue'
import ContactPage from './components/ContactPage.vue'
import FaqsPage from './components/FaqsPage.vue'

import $ from 'jquery'

window.$ = $

const routes = [
	{ path: '/', component: HomePage },
	{ path: '/about-us', component: AboutPage },
	{ path: '/our-products', component: ProductsPage },
	{ path: '/services', component: ServicePage },
	{ path: '/contact-us', component: ContactPage },
	{ path: '/faqs', component: FaqsPage },
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0);

	if (to.query.r == 'true') {
		next(() => {
			window.location.reload(true);
		});
	} else {
		next();
	}

});

const app = createApp(App)

app.use(router)
app.mount('#app')