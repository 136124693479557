<template>
	<div class="fix">
		<header class="main-header main-header-one">
			<div id="sticky-header" class="menu-area">
				<div class="main-header-one__outer">
					<div class="main-header-one__right">
						<div class="container">
							<div class="menu-area__inner">
								<div class="mobile-nav-toggler align-items-center">
									<div class="site-ligo">
										<RouterLink to="/">
											<img src="assets/images/logo/orel-logo-white.png" style="width: 40px; height: 50px;" alt="site-logo" />
										</RouterLink>
									</div>
									<i class="fas fa-bars"></i>
								</div>
								<div class="menu-wrap">
									<nav class="menu-nav">
										<div class="main-header-one__inner">
											<div class="main-header-one__top">
												<div class="main-header-one__top-pattern"></div>
												<div class="main-header-one__top-inner">
													<div class="main-header-one__top-left">
														<div class="header-contact-info">
															<ul>
																<li>
																	<div class="icon-box">
																		<i class="flaticon-maps-and-flags"></i>
																	</div>
																	<p>Gogonya road Nsambya</p>
																</li>
																<li>
																	<div class="icon-box">
																		<i class="flaticon-gmail-logo"></i>
																	</div>
																	<p>
																		<a href="mailto:yourmail@email.com">orelmedicalsupplies@gmail.com</a>
																	</p>
																</li>
																<li>
																	<div class="icon-box">
																		<i class="flaticon-call"></i>
																	</div>
																	<p>
																		<a href="tel:0779266772">+256779266772</a>
																	</p>
																</li>
															</ul>
														</div>
													</div>
													<div class="main-header-one__top-right">
														<div class="inner">
															<div class="header-social-links">
																<ul>
																	<li>
																		<a href="#"><i class="fa-brands fa-facebook-f"></i></a>
																	</li>
																	<li>
																		<a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
																	</li>
																	<li>
																		<a href="#"><i class="fa-brands fa-twitter"></i></a>
																	</li>
																	<li>
																		<a href="#"><i class="fa-brands fa-instagram"></i></a>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div class="main-header-one__bottom">
												<div class="main-header-one__bottom-left d-flex">
													<div class="site-ligo d-flex align-items-center">
														<RouterLink to='/'><img src="assets/images/logo/orel-logo-dark.png" style="width: 40px; height: 50px;" alt="site-logo" /></RouterLink>
													</div>
													<div class="navbar-wrap main-menu">
														<ul class="navigation">
															<li>
																<RouterLink :to="{path: '/', query: { r: true }}">Home</RouterLink>
															</li>
															<li>
																<RouterLink :to="{ path: '/about-us', query: {r: true} }">About Us</RouterLink>
															</li>
															<li>
																<RouterLink :to="{ path: '/services', query: {r: true} }">Our Services</RouterLink>
															</li>
															<li>
																<RouterLink :to="{ path: '/our-products', query: {r: true} }">Our Products</RouterLink>
															</li>
															<li>
																<RouterLink :to="{ path: '/contact-us', query: {r: true} }">Contact Us</RouterLink>
															</li>
														</ul>
													</div>
												</div>

												<div class="main-header-one__bottom-right">
													<div class="search-box">
														<a href="#" class="main-menu__search search-toggler icon-magnifying-glass"></a>
													</div>
													<div class="btn-box">
														<!-- <a class='thm-btn' href='https://www.tradehub.intanode.co.ug/' target="_blank">
															<span class="txt">Shop Now</span>
														</a> -->
													</div>
												</div>
											</div>
										</div>
									</nav>
								</div>
							</div>
							<div class="mobile-menu">
								<nav class="menu-box">
									<div class="close-btn">
										<i class="fas fa-times"></i>
									</div>
									<div class="nav-logo">
										<RouterLink to="/">
											<img src="assets/images/logo/orel-logo-dark.png" style="width: 40px; height: 50px;" alt="Logo" />
										</RouterLink>
									</div>
									<div class="menu-outer">
									</div>
									<div class="contact-info">
										<div class="icon-box">
											<i class="flaticon-call"></i>
										</div>
										<p>
											<a href="tel:+256779266772">+256779266772</a>
										</p>
									</div>
									<div class="social-links">
										<ul class="clearfix list-wrap">
											<li>
												<a href="#"><i class="fab fa-facebook-f"></i></a>
											</li>
											<li>
												<a href="#"><i class="fab fa-twitter"></i></a>
											</li>
											<li>
												<a href="#"><i class="fab fa-instagram"></i></a>
											</li>
											<li>
												<a href="#"><i class="fab fa-linkedin-in"></i></a>
											</li>
											<li>
												<a href="#"><i class="fab fa-youtube"></i></a>
											</li>
										</ul>
									</div>
								</nav>
							</div>
							<div class="menu-backdrop"></div>
						</div>
					</div>
				</div>
			</div>
		</header>

		<slot/>

		<footer class="footer-sec">
			<div class="section-overlay sec-pt">
				<div class="container">
					<div class="row">
						<div class="col-sm-6 col-xl-3">
							<div class="footer-widget">
								<div class="footer-widget-logo">
									<img src="assets/images/logo/orel-logo-white.png" style="width: 40px; height: 50px;" alt="site-logo" />
								</div>
								<div class="footer-widget-content">
									<p class="ft-text">
										OREL MEDICAL SUPPLIES LIMITED
										Engineering with Integrity
									</p>
								</div>
							</div>
						</div>
						<div class="col-sm-6 offset-xl-1 col-xl-2">
							<div class="footer-widget">
								<h4 class="footer-widget-title tx-white">Useful Links</h4>
								<div class="footer-widget-content">
									<div class="footer-links">
										<ul>
											<li><RouterLink :to="{ path:'/about-us', query: { r: true } }">About Us</RouterLink></li>
											<li><RouterLink :to="{ path:'/services' , query: { r: true } }">Services</RouterLink></li>	
											<li><RouterLink :to="{ path: '/our-products' , query: { r: true } }">Our Products</RouterLink></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="col-sm-6 offset-xl-1 col-xl-2">
							<div class="footer-widget pages-links">
								<h4 class="footer-widget-title tx-white">Useful Links</h4>
								<div class="footer-widget-content">
									<div class="footer-links">
										<ul>
											<li><RouterLink :to="{ path: '/contact-us' , query: { r: true } }">Contact Us</RouterLink></li>
											<!-- <li><RouterLink to="/team">Our Team</RouterLink></li> -->
											<li><RouterLink :to="{ path: '/faqs' , query: { r: true } }">Faqs</RouterLink></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="col-sm-6 col-xl-3">
							<div class="footer-widget">
								<h4 class="footer-widget-title tx-white">Contact Info</h4>
								<div class="footer-widget-content">
									<div class="footer-widget">
										<div class="footer-widget-content">
											<div class="qinfo-item">
												<div class="qinfo-box">
													<i class="flaticon-maps-and-flags"></i> Location
													<br/>
													P.O.Box 118937, Kampala GPO, Gogonya road Nsambya
												</div>
											</div>
											<div class="footer-social">
												<div class="footer-social-icon d-flex">
													<a href="https://www.facebook.com/" target="_blank"> <i class="fa-brands fa-facebook-f"></i></a>
													<a href="https://www.linkedin.com/" target="_blank"><i class="fa-brands fa-linkedin-in"></i></a>
													<a href="https://twitter.com/" target="_blank"><i class="fa-brands fa-twitter"></i></a>
													<a href="https://www.instagram.com/" target="_blank"><i class="fa-brands fa-instagram"></i></a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-fluid">
					<div class="row">
						<div class="col-sx-12 p-0">
							<hr class="footer-hr" />
						</div>
					</div>
				</div>
				<div class="container">
					<div class="row footer-copyright">
						<div class="col">
							<div class="footer-copyright-text text-center">
								<p class="mb-0">© Orel Medical Suppliers {{ date }}. All Rights Reserved, Courtesy of Intanode Technologies Ltd</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	</div>

</template>

<script setup>
	import {onMounted} from 'vue';

	onMounted(() => {

		window.$('#preloader').delay(0).fadeOut();

		var loader = document.getElementById('preloader');
		window.addEventListener('load', function () {
			loader.style.display = 'none';
		});
	})

	const date = (new Date()).getFullYear();
</script>