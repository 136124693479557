<template>
	<MainLayout>
		<section id="site-breadcumb" style="background-image: url('assets/images/breadcumb/breadcumb-bd.svg')">
			<div class="site-breadcumb-wrapper">
				<div class="container">
					<div class="row">
						<div class="col">
							<div class="breadcumb-content">
								<h1 class="breadcumb-title">FAQs</h1>
								<ul class="page-navigator">
									<li class="home"><RouterLink to='/'>Home</RouterLink></li>
									<li class="current-page"><RouterLink to="#">Faqs</RouterLink></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="faq-sec sec-ptb">
			<div class="container">
				<div class="row">
					<div class="col-lg-6 d-flex justify-content-center flex-column">
						<div class="sec-content mb-5">
							<h6 class="sec-sub-title">FAQs</h6>
							<h2 class="sec-title">Looking For Answers?</h2>
						</div>
					</div>
				</div>
				<div class="row">

					<div v-for="(chunk, chunkIndex) in accordionChunks" :key="chunkIndex" class="col-lg-6">
						<div id="accordion" class="accordion-container">
							<template v-for="(accordion, index) in chunk" :key="index">
								<h4 class="accordion-title" @click="toggleAccordion(index + chunkIndex * 2)" :class="{ open: accordion.open }">{{ accordion.title }}</h4>
								<div class="accordion-content" :style="{ display: accordion.open ? 'block' : 'none' }">
									<p>{{ accordion.content }}</p>
								</div>
							</template>
						</div>
					</div>

					
				</div>
			</div>
		</section>
	</MainLayout>
</template>

<script>
	import MainLayout from './Layout/MainLayout.vue'

	export default {
		components: {
			MainLayout
		},
		data() {
			return {
				accordions : [
				{ 
					title: 'What does Orel Medical Supplies Ltd offer?', 
					content: 'Orel Medical Supplies Ltd offers various medical supplies like equipment, gloves, and medicines to hospitals and healthcare units.', 
					open: false 
				},
				{ 
					title: 'How does Orel ensure product quality?', 
					content: 'We make sure our products are of good quality by checking them carefully and keeping records of their safety.', 
					open: false 
				},
				{ 
					title: 'Can Orel help with buying medical supplies?', 
					content: 'Yes, we can help you buy medical supplies by preparing documents for you to get them easily.', 
					open: false 
				},
				{ 
					title: 'Does Orel train healthcare workers to use equipment?', 
					content: 'Yes, we provide training for healthcare workers to learn how to use the equipment we sell.', 
					open: false 
				},
				{ 
					title: 'How does Orel support customers after purchase?', 
					content: 'We support our customers even after they buy from us by helping them with any problems they might have.', 
					open: false 
				},
				{ 
					title: 'Is Orel open to collaborations on healthcare projects?', 
					content: 'Yes, we\'re open to working together on new ideas to improve healthcare. Just let us know your thoughts, and we can talk about how to make them happen.', 
					open: false 
				}
				]
			}
		},
		computed: {
			accordionChunks() {
				const chunkSize = 2;
				const chunks = [];
				for (let i = 0; i < this.accordions.length; i += chunkSize) {
					chunks.push(this.accordions.slice(i, i + chunkSize));
				}
				return chunks;
			}
		},
		methods: {
			toggleAccordion(index) {
				this.accordions.forEach((accordion, i) => {
					accordion.open = (i === index);
				});
			}
		}
	}


</script>